<template>
  <div id="error-modal-container">
    <div id="error-content">
        <div id="error-msg">
          <h1>{{$t('errors.404')}}</h1>
        </div>        
      <div id="alien">
        <img src="/img/elements/error/404_alien.svg" width="573" height="555"/>
      </div>
      <div id="friends">
        <img src="/img/elements/error/404_friends.svg" width="181" height="617"/>
      </div>
    </div> 
  </div>  
</template>

<script>
export default {
  name: "NotFound", 
  mounted (){
    this.tracking({
      type: 'page',
      title: '/404',
    });
  },   
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
#error-modal-container {
  position: absolute;
  top: 0px;
  margin: 0 auto;
  width: 1024px;
  height: 768px;

  #error-msg {
    margin: 100px auto 30px;
    width: 300px;
    
    text-align: center;
      h1 {
        color: $ps-white;
      }    
  }
  #alien {
    position:absolute;
    top: 125px;
    left: 15%;
  }
  #friends {
    position:absolute;
    top: 0px;
    right: 80px;    
  }
}
</style>